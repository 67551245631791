/* eslint no-console:0 */
import { createRoot } from 'react-dom/client';
import '../assets/stylesheets/antd.less';
//import '../assets/stylesheets/global.sass';

import Rails from "@rails/ujs";
import App from '../react/devise/DeviseApp';
import React from 'react';
//import * as ActiveStorage from "@rails/activestorage";

if(!window._rails_loaded) Rails.start();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);